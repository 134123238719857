<template>
  <div class="myAdvice">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div class="list" v-for="(item, index) in listData" :key="index">
        <div class="text">
          {{ item.content }}
        </div>
        <div class="reply" v-if="item.managerContent">
          回复：{{ item.managerContent }}
        </div>
        <div class="time">{{ item.insertTime }}</div>
      </div>
    </v-list>
    <v-dialog v-model="isDialog" @confirm="confirm" confirmButtonText="提交">
      <div class="contentBox">
        <div class="contentBox-body">
          <v-input
            type="textarea"
            placeholder="请输入意见"
            :maxlength="300"
            v-model="form.content"
          ></v-input>
        </div>
      </div>
    </v-dialog>
    <div class="footer" @click="submitAdvice">发布意见</div>
  </div>
</template>

<script>
import { findFeedbackPageUrl, saveOrUpdateFeedbackUrl } from "./api";
export default {
  name: "myAdvice",
  components: {},
  props: {},
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      isDialog: false,
      listData: [],
      form: {
        content: "",
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    submitAdvice() {
      this.$router.push({
        name: "myAdviceForm",
      });
    },
    async confirm() {
      let params = {};
      if (!this.form.content) {
        this.$toast({ message: "请填写意见", duration: 2000 });
        return;
      }
      params = {
        content: this.form.content,
        insertUser: this.userId,
        houseId: this.roomId,
      };
      let res = await this.$axios.post(`${saveOrUpdateFeedbackUrl}`, params);

      if (res.code === 200) {
        this.isDialog = false;
        this.form.content = "";
        this.init();
      }
    },
    init() {
      this.requestData.curPage = 1;
      this.findFeedbackPage();
    },
    findFeedbackPage() {
      let params = {};
      params = {
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios
        .get(`${findFeedbackPageUrl}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            if (this.requestData.curPage == 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.activityId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.activityId) || this.listData.push(item);
              });
            }
          } else {
          }
          this.requestData.curPage++;
        });
    },
    onLoad() {
      this.findFeedbackPage();
    },
  },
};
</script>

<style scoped lang="less">
.myAdvice {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  box-sizing: border-box;
  padding: 40px 32px;
  .list {
    min-height: 256px;
    width: 100%;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .reply {
      margin-top: 20px;
      max-height: 180px;
      overflow-y: auto;
      font-size: 26px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 36px;
    }
    .text {
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      line-height: 44px;
    }
    .time {
      margin-top: 20px;
      text-align: right;
      font-size: 26px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 36px;
    }
  }
  .footer {
    text-align: center;
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
    border-radius: 10px;
    font-size: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
  }
}
</style>
